import axios from "axios"
import { API_ROOT, ENV } from "gatsby-env-variables"

const config = {
  headers: {
    Authorization:
      typeof window !== "undefined" && localStorage.getItem("insightToken"),
  },
}

export const mfaSetup = params =>
  axios.post(`${API_ROOT}${ENV}/mfa/setup`, params, config)

export const verifyMfaSetup = params =>
  axios.post(`${API_ROOT}${ENV}/mfa/verify-setup`, params, config)

export const verifyMfaLogin = params =>
  axios.post(`${API_ROOT}${ENV}/mfa/verify-login`, params, config)
